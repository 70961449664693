.editor { background-color: white; }
.editor .toolbar {
    border:1px solid rgb(0, 0, 0);
    background-color: rgb(46, 46, 46);
    height: 40px;
}
.editor .toolbar button {
    height: 100%;
    border-radius: 0;
    border: none;
    background-color: rgb(95, 95, 95);
    color: white;
    margin-right: 2px;
    min-width: 90px;
}
.editor .toolbar button:hover {background-color: rgb(192, 192, 192);}

.editor h1 { text-align: center;}
.editor h2 {font-size: 1.1rem;margin-top:30px;}
.editor h3 {margin-bottom: 5px;font-size: 0.8rem;color:blue}
.editor .content { margin: 0 20px; }

.editor .grid {border:1px solid black;font-size: 0.9rem;background-color: white;}
.editor .row {display: flex;padding:0 10px }
.editor .row.header { background-color: rgb(56, 68, 40);color:white;font-weight: 600; }
.editor .row.header:hover { background-color: rgb(56, 68, 40); }
.editor .row:hover {background-color: rgb(233, 245, 215);}
.editor .col1 {width: 100px;min-width:100px;}
.editor .col2 {width: 100%;}

.editor .col-buttons {width:200px;min-width:200px;margin:5px 0; display: flex;justify-content: flex-end;align-items: center;}
.editor .col-buttons button {width: 60px;font-size: 0.7rem;}

/* MOBILE */
@media screen and (max-width:750px) {
    .editor .row {flex-direction: column;padding:10px 5px;font-size: 1rem;}
    .editor .col-buttons {justify-content: flex-start;width: fit-content;}
    .editor .col-buttons button { padding: 5px 20px;width: fit-content;font-size: 0.9rem; }
    .editor .content {margin:0 5px}
}


.editor .controller .form {margin-top: 40px;}
.editor .controller .form span { display: block;font-weight: 600;}
.editor .controller .form .title {width:100%}
.editor .controller .form .date {width:100px}
.editor .controller .form .body {width:100%;min-height: 350px;}

@media screen and (max-width:750px) {
    .editor .controller input[type=text] {font-size: 1rem;}
    .editor .controller textarea {font-size: 1rem;}
}

