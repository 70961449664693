/*===========================================================
      MAIN SETTINGS
  ===========================================================*/
* {box-sizing: border-box;border-collapse: collapse;}
ul {list-style: none;padding:0;} 
body div iframe { height: 0px;position: absolute;top:-100%; } /* react bug */
p {margin:0;padding:0;}

/*===========================================================
    PAGE VIEW AND CONTENT
  ===========================================================*/
/* -- page adjustments  */
.page {display: flex;flex-direction: column;height: 100%;min-height: calc(100vh - 70px);} /*remove space for header*/
.page .grow { flex-grow:1; }

.main {
    max-width: 1200px;
    width: 100%;
    margin: 20px auto 50px auto;
    padding-top:50px;
    padding-bottom: 50px;
}
.main h1 { margin-top:0}


/* -- background settings */
.bg-fixed {
    z-index: -1;
    object-position:center;
    object-fit: cover;
    position: fixed !important;
    top:0;
    left:0;
    height: 100vh;
    width: 100%;
    transform: translate3d(0,0,0); 
    filter:brightness(120%);
  }

.bg-white { background-color: white;}
.bg-white-t {background-color: rgb(255, 255, 255,0.9);}

/* -- content handling */
.content p {padding: 0px 20px 15px 20px;}
.content h2, .content h3, .content h4 { padding-left: 20px; }
.content::after { content: "";clear:both;display: block; }
.content img {
    max-height: 50%;
    max-width: 40%;
    object-fit: contain;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    pointer-events: none;
}
.content img.left { float:left}
.content img.right { float:right}
.content img.center { display: block;margin-left: auto; margin-right: auto;}
.content img.bar { display: block;width: 100%;max-width: none;max-height: none;max-height: 200px;margin:0;object-fit: cover; }


/*===========================================================
    ICONS
  ===========================================================*/
.icons {padding: 30px 0px;display: flex;justify-content: center;gap: 5px;}
.icon {
  background-color: rgb(110, 149, 207);
  border-radius: 5px;
  font-size: 2rem;
  border: 2px solid rgb(85, 87, 128);
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding:0;
  margin:0;
  width:50px;
  height:50px;
  font-weight: 600;
}
 h1 .icon, h2 .icon { margin-left: 10px; }
.icon:disabled {background-color: rgb(199, 199, 199);border:2px solid rgb(236, 236, 236);}
.icon:hover:enabled{background-color: rgb(207, 222, 240);border:2px solid rgb(196, 196, 196);transition: 0.5s;}
.icon.edit::after { content:'✎';color:white; } /* '&#x270E;' */
.icon.delete::after { content:'🗑';color:white; } /* '&#x270E;' */
.icon.cancel::after { content:'✖';color:white; }
.icon.save::after { content:'🖫';color:white;} /* &#128427; &#x1F5AB; */
.icon.add::after { content:'✚';color:white;} /* &#10010; */
/* =================================================================== */



/*===========================================================
    CUSTOM SETTINGS 
  ===========================================================*/
/* - Headers */
h1,h2,h3 { color:black;font-weight: 700; }
h1 { font-size: 2rem;text-align: center;margin-top:30px;margin-bottom: 30px;}
h2 { font-size: 1.5rem;text-align: left;margin:0;padding-bottom: 15px;padding-top:30px;}
h3 { font-size: 1.1rem;text-align: left;padding-bottom:10px;padding-top:20px;margin:0;}
h4 { font-size: 1rem;text-align: left;font-style: italic;}

/* COMPACT VIEW  */
@media screen and (max-width:1200px) {
    .main { margin-top:0;margin-bottom: 0;}    
}

/* MOBILE VIEW  */
@media screen and (max-width:500px) {
    .content img.mobile-bar, .content img.bar {
        display: block;
        margin:0;
        width: 100%;
        max-height: 200px;
        max-width: none;
        min-width: none;
        min-height: none;
        object-fit: cover;
        margin-bottom: 20px;
    }
    
}
