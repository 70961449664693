.ml-img-text {
    text-align: center;
    font-style: italic;
    font-weight: 700;
    font-size: 0.9rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
}

.component {display: grid;background-color: gray;}
.component h2 {
    background-color: rgb(54, 54, 54);
    color: yellow;
    font-size: 1rem;
    font-weight: 700;
    padding:5px 20px;
    margin:0;
    width: 100%;
}
.component .form {
    position: relative;
    border: 1px solid white;
    padding: 30px;
    width: fit-content;
    color:white;
    margin: 50px auto 20px auto;
}
.component .form h3 {
    position: absolute;
    margin:0;padding: 0 5px;
    top:-14px;
    left:15px;
    background-color: gray;
    color:white;
}

.component .form-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    margin-bottom: 10px;
}
.component .form-row span {display: block;font-weight: 700;font-size: 0.9rem;}
.component .form-row select, .component .form-row input[type=text] {width:130px}

.component .response {
    display: grid;
    justify-content: center;
    text-align: center;
    color:black;
    font-weight: 700;
}
.component .response img { display: none;}
.component .response.load img { display: block;width: 45px;margin: auto; }
.component .response.load p { display: none;}

.component .text-error input[type=text] {color:red}

@media screen and (max-width:500px) {
    .ml-img-text { width: 100%; }

    .component .form {width: 90%;}
    .component .form-row { flex-direction: column;gap:10px;}
    .component .form-row div, .component .form-row select, .component .form-row input[type=text] {width: 100%;}
}