.loader.wrapper {
    position: fixed;
    z-index: 10;
    top:0px;
    left:0px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(255, 255, 255, 0.7);
    display: flex;

}

.loader.wrapper .form {
    background-color: white;
    width: 220px;
    border-radius: 10px;
    border: 1px solid gray;
    margin:0 auto;
    height: fit-content;
    text-align: center;
    padding: 10px 40px;
    margin-top:30vh;
}
.loader.wrapper .form span {font-weight: 600;}
.loader.wrapper .form img { width: 70px; }

/* MOBILE VIEW */
@media screen and (max-width:500px) {
    .loader.wrapper .form img { width: 50px; }
    .loader.wrapper .form { width: 180px;padding:10px 30px; }
}
