.game {
   background-color: rgb(255, 255, 255,0.9);
}

.game .game-select { 
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; 
    background-color: rgb(213, 218, 221);
    border:3px solid rgb(104, 118, 145);
    border-radius: 15px;
    padding:20px;
    margin-bottom: 50px;
}
.game .game-select input[type=range] {
    width: 100%;
    height: 50px;
}

/* BUTTON GROUP */
.game h2 {
    color:black;
    margin-top:20px;
    margin-bottom: 20px;
    text-align: center;
}
.game .button-group {
    display: flex;
    min-height: 70px;
}
.game .button-group li {
    width: 100%;
    background-color: rgb(57, 77, 82);
    color:white;
    font-weight: 600;
    border: 1px solid rgb(28, 65, 65);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}
.game .button-group li.active {
    background-color: rgb(199, 230, 228);
    color: black;
}
.game .button-group.large-text {font-size: 3rem;padding: 0;margin:0;}

.game .start-button { 
    background-color: rgb(103, 235, 154);
    width: 80%;
    height: 70px;
    border-radius: 10px; 
    font-size: 1.6rem;   
    font-weight: 700; 
}
.game .start-button:disabled {
    background-color: rgb(130, 173, 147);
    color:white;
}

/* GAME VIEW */
.game .game-window {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 4rem;
    font-weight: 800;
}

.game .score {
    width: 100%;
    background-color: rgb(204, 204, 204);
    font-size: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    position: relative;
} 
.game .score div {margin-left: auto;margin-right: auto;}

.game .hide {display: none;}
.game .show {display: block;}

@keyframes fadeInAnimation {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
.game .outcome {
    position: absolute;
    display: flex;
    font-size: 10rem;
}
.game .outcome.lose::after {content: '💀';}
.game .outcome.win::after {content: '🤩';}
.game .outcome.show {
    animation: fadeInAnimation ease-in 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.game .outcome.hide { display: none; }


/* NUMPAD */
.game .numpad {
    position: relative;
}
.game .numpad-disabled {
    position: absolute;
    background-color: rgba(170, 170, 170, 0.6);
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
}
.game .numpad-row {
    display: flex;
    margin-left: auto;margin-right: auto;
    width: 100%;
    max-width: 500px;
}
.game .numpad-row button {
    width: 100%;
    max-height: 100px;
    font-size: 3rem;
}
.game .numpad-row .delete {
    background-color: rgb(252, 208, 208);
    color:rgb(112, 0, 0);
    font-weight: 900;
}
.game .numpad-row .enter {
    background-color: rgb(200, 235, 200);
    color:green;
    font-weight: 900;
}

.game .numpad-row .value {
    width: 100%;
    min-height: 70px;
    color:blue;
    font-size: 3rem;
    background-color: rgb(225, 225, 252);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.game .numpad-row-bottom { 
    padding:20px;
    width: 100%;
    background-color: black; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.game .numpad-row-bottom button {padding:20px 30px;background-color: white;border-radius:10px;}

@media screen and (max-width:500px){
    .game .numpad-row button {
        font-size: 1rem;
        height:8vh;
    } 
    .game .score {
        font-size: 2rem;
    }
    .game .numpad-row .value { min-height: 50px;font-size: 2rem;}
    .game .numpad-row-bottom { height: 100px;}
    .game .outcome {font-size: 7rem;}
}