.fn_login {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.fn_login .header {
    font-size: 1.1rem;
    font-weight: 700;
    color:rgb(70, 70, 70);
    text-align: center;
}

.fn_login input { width:100%;} 

.fn_login .password { display: grid; }
.fn_login .password button {text-align: right;margin-right: none;margin-left: auto;}


.fn_login .button-link {
    border:none;
    background-color: transparent;
    font-size: 0.8rem;
    color:rgb(0, 0, 94);
    padding:0;
    cursor: pointer;
}
.fn_login .button {width: fit-content;margin: 0 auto;padding:3px 20px;}

.fn_login .space {height: 10px;}
.fn_login .big-space {height: 30px;}

.fn_login .centrify {display: flex;align-items: center;justify-content: center;text-align: center;}

/* text */
.fn_login .info { height: 40px; font-size: 0.8rem;font-weight: 500;color:rgb(46, 78, 31)}

.fn_login .loader { font-size: 0.9rem;font-weight: 500;color:gray }
.fn_login .loader img { width: 35px; }

.fn_login .error { color:red}
.fn_login .hide { display: none;}

@media screen and (max-width:500px) {
    .fn_login .button {width: 100%;padding:5px 0;font-size: 1rem;}
    .fn_login .button-link { font-size: 1rem; }
}

/* handle account */
.fn_login .account-id { font-size: 0.9rem;font-weight: 500; color:rgb(9, 37, 97);text-align: center;}
.fn_login .change-pwd { display: flex;height: 25px; }
