.home-overlay {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position:fixed;
    top:0px;left:0px;
    background-color: rgb(0, 0, 0,0.2);
    z-index: -1;
}

.home-title {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-title h1, .home-title h2 { color:white; margin: 0;}
.home-title h1 { font-size: 3rem;}
.home-title h2 { font-size: 1.5rem;text-align: center;padding:10px 10px 0 10px}
.home-title hr {width: 100%;margin: 0;}

.home-item {
    position: relative;
    display: flex;
    gap:20px;
    background-color: white;
    max-width: 1200px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    text-decoration: none;
    color: black;
}
.home-item img {
    width: 230px;
    height: 230px;
    object-fit:cover;
}
.home-item span { padding: 0 0 10px 0;display: block;font-weight: 700;font-size: 1.3rem; }
.home-item div { padding: 20px 20px 20px 0 }
.home-item div p { padding-bottom:15px; }
.home-item .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    transition: 0.5s;
}
.home-item .overlay:hover { opacity: 0.4;transition: 0.5s;}

/* MOBILE VIEW */
@media screen and (max-width:700px){
    .home-title h2 { font-size: 0.8rem;}

    .home-item { flex-direction: column;gap:0; }
    .home-item img {width: 100%;height: 120px;}
    .home-item div { padding:10px; }
    .home-item span { text-align: center;}
    .home-item .overlay { display: none; }

}
