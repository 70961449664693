/* for "filter" => calculate color using: https://codepen.io/sosuke/pen/Pjoqqp */
/* Font for logo */
@font-face {
    font-family: 'Harlow';
    src: url('./fonts/HARLOWSI.woff2') format('woff2');
}
@font-face {
    font-family: 'Pristina';
    src: url('./fonts/PRISTINA.woff2') format('woff2');
}

.navbar {
    background-color:rgb(34, 34, 34);
    width: 100%;
    user-select: none;
    position: relative;
}
.navbar.transparent {background-color:rgba(34, 34, 34, 0.8); }
.navbar.fixed {z-index: 2;position: fixed;top:0px;left:0px}
.navbar-H { height: 70px; }
.navbar-W { max-width: 1200px; }

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

/* THE LOGO */
.navbar-logo { display: flex;align-items: center;width: fit-content;flex-wrap: nowrap;}
.navbar-logo img {  height: 100%; }
.navbar-logo span { 
    color:white;
    padding-left: 15px;
    font-family: Pristina;
    font-size: 3rem;
    padding-top:5px;
}

/* THE LINKS */
.navbar ul {list-style: none;margin:0;display: flex;align-items: center;}

.navbar-link { cursor: pointer;padding:15px;font-size: 1.1rem; }
.navbar-link:hover {background-color: rgb(82, 64, 57);transition: 0.3s ease-out; }
.navbar-link a {text-decoration: none;color: white;}

.navbar-link-login { color:white;border:1px solid white;padding:5px 8px;font-size: 0.7rem;cursor: pointer;margin-left: 5px; }

/* MENU BUTTON */
.navbar-menu-btn {display: none;}

/* WIDTH FIT */
@media screen and (max-width:1200px) {
    .navbar ul {margin-right: 5px;}
}

/* MENU SWITCH */
@media screen and (max-width:1000px) {
    .navbar-menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width:70px;
    }
    .navbar-menu-btn img {height: 50px;filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(104%); }
    .navbar ul {
        position: absolute;
        display: grid;
        top:70px;
        margin:0;
        padding:0;
        background-color: white;
        width: 100%;
    }
    .navbar ul.hide { display:none; }

    .navbar-link { padding:10px 20px; }
    .navbar-link:hover { transition: none;background-color:inherit; }
    .navbar-link a { color:black; }

    .navbar-link-login { background-color: rgb(68, 68, 68);padding:15px 0;text-align: center;font-weight: 700;; } 
}


/*
.navbar .nav-link {
    user-select: none;
    margin-left:5px;
    cursor: pointer;
    padding:15px;
}
.navbar .nav-link:hover {
    background-color: rgb(82, 64, 57);
    transition: 0.3s ease-out;
}
.navbar .nav-link a {
    text-decoration: none;
    color:white;
    font-size: 1.1rem;
}

.navbar .nav-login-link {
    color: white;
    font-size:0.7rem;
    border: 1px solid white;
    padding: 5px 8px;
    cursor: pointer;
    margin-left:10px;
}
*/



/*

.navbar .nav-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:95%;
    max-width:1200px;
    box-sizing: border-box;
    margin:auto;
}
*/
/* LOGO SETTINGS */
/*.navbar .logo {
    width: fit-content;
    display: flex;
    align-items: center;
}
.navbar .logo img {
    width: 70px;
    margin-right: 10px;
    padding: 5px;
    user-select: none;
    border-radius: 5px;
}
.navbar .nav-title {
    color:white;
    font-family: Pristina;
    font-size: 50px;
    margin-top:10px;
    user-select: none;
    white-space: nowrap;
}
*/

/* MENU LINKS SETTINGS */
/*.navbar  .nav-button {display: none;}
.navbar  .nav-button img {width:50px;filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(104%); }

.navbar .menu {display:flex;align-items: center;width:fit-content;}

.navbar .nav-link {
    user-select: none;
    margin-left:5px;
    cursor: pointer;
    padding:15px;
}
.navbar .nav-link:hover {
    background-color: rgb(82, 64, 57);
    transition: 0.3s ease-out;
}
.navbar .nav-link a {
    text-decoration: none;
    color:white;
    font-size: 1.1rem;
}

.navbar .nav-login-link {
    color: white;
    font-size:0.7rem;
    border: 1px solid white;
    padding: 5px 8px;
    cursor: pointer;
    margin-left:10px;
}
*/

/* TRANSFORM LINKS TO GRID LIMIT */
/*@media screen and (max-width:950px) {
    .navbar .nav-content {width: 100%;padding:0;}
    .navbar .nav-button {display: flex;margin-right: 20px;}
    .navbar .logo {margin-left: 15px;}

    .navbar .menu {
        display:grid;
        background-color: white;
        width: 100%;
        margin:0;
        position: absolute;
        top:70px;
        left:-100%;
        z-index: -1;
        opacity: 0;
        transition: all 0.2s ease;
    }
    .navbar .menu.clicked {
        left:0px;
        opacity: 1;
        transition: 0.2s;
    }
    .navbar .nav-link {margin:0;}
    .navbar .nav-link:hover {background-color: rgb(202, 202, 202);}
    .navbar .nav-link a { color:black;}
    .navbar .nav-login-link { 
        text-align: center;
        padding:10px;
        background-color: rgb(73, 70, 70); 
        cursor: pointer;
        margin:0;
    }
}
*/
/* MOBILE SUPPORT */
/*@media screen and (max-width:500px) {
    .navbar .nav-title {font-size: 35px;}
    .navbar .nav-button {margin-right: 10px;}
    .navbar .nav-button img {width:40px;}
}
*/