@font-face {
    font-family: 'Pristina';
    src: url('./fonts/PRISTINA.woff2') format('woff2');
}

.footer {
    background-color: #0f1611;
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-items: center;
}
.footer-content {
    max-width: 1200px;
    width: 100%;
    margin:auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color:white;
}
.footer-logo {
    display: flex;
    justify-items: center;
    width: fit-content;
    gap:10px;
}
.footer-logo img { height: 50px; }

.footer-info {display: grid;font-size: 1rem;color:white;line-height: 1;}
.footer-title { font-family: Pristina; font-size: 1.7rem; }

.footer-links ul {list-style: none;margin: 0;padding: 0;}
.footer-links ul li a { text-decoration: none;color:white; }

.footer-links .text-default { font-size: 0.8rem;}
.footer-links .text-small { font-size: 0.6rem; }
.footer-links .text-divider {margin: 0 5px;}
.footer-links li a, .footer.links li a:active, .footer.links li a:hover {text-decoration: none;color:rgb(207, 207, 207);}
 
@media screen and (max-width:550px) {
    .footer-content {flex-direction: column;gap:20px}
}
